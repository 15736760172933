<template>
  <div id="bg">
    <div class="search">
      <div class="search-box">
        <span class="search-box-title">银行卡类型：</span>
        <el-input v-model="search.card_type" placeholder="银行卡类型" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="getList()">查询</el-button>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="dialogAdd = true,title = '添加银行类型'">添加银行类型</el-button>
      </div>
    </div>
    <el-table :data="tableData" :header-cell-style="{background:'#F7F8FA'}">
      <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="bank_name" label="银行卡类型" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="weight" label="权重" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="操作" width="250px" align="center">
        <template #default="scope">
          <span class="operation" v-if="scope.row.role_id != 1" @click="editor(scope.row)">编辑</span>
          <span class="operation" v-if="scope.row.role_id != 1" @click="del(scope.row)">删除</span>
        </template>
      </el-table-column>
    </el-table>

    <!-- 添加修改 -->
    <el-dialog :title="title" v-model="dialogAdd" width="492px" top="35vh" :close-on-click-modal="false" @close="closeDialog">
      <div class="form_box">
        <div class="form_title">银行卡类型：</div>
        <el-input v-model="addArr.card_type" placeholder="银行卡类型" size="small"></el-input>
      </div>
      <div class="form_box">
        <div class="form_title">权重：</div>
        <el-input v-model="addArr.weight" placeholder="权重" size="small"></el-input>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="add" v-if="title == '添加银行类型'">确认添加</el-button>
          <el-button type="primary" @click="edit" v-else>确认修改</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { bankList, add, edit, del } from "@/api/system/cardType";
export default {
  name: "systemCardType",
  components: {},
  data() {
    return {
      tableData: [], //数据
      search:{card_type:""}, // 搜索
      row: [], //选中的数据
      title: "", //弹窗标题
      dialogAdd: false, //添加弹窗
      addArr: { card_type: "", weight: "" }, //添加数组
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    //获取管理员数据
    getList() {
      bankList([
        { key: "card_type", val: this.search.card_type },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.tableData = res.data;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //添加数据
    add() {
      add([
        { key: "card_type", val: this.addArr.card_type },
        { key: "weight", val: this.addArr.weight },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.dialogAdd = false;
            this.getList();
            this.$message({ message: res.msg, type: "success" });
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //编辑前数据填充
    editor(row) {
      this.row = row;
      this.title = `编辑银行卡类型(ID：${row.id})`;
      this.addArr = { card_type: row.bank_name, weight: row.weight };
      this.dialogAdd = true;
    },
    //编辑
    edit() {
      edit([
        { key: "id", val: this.row.id },
        { key: "card_type", val: this.addArr.card_type },
        { key: "weight", val: this.addArr.weight },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.dialogAdd = false;
            this.getList();
            this.$message({ message: res.msg, type: "success" });
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //关闭编辑弹窗后清空
    closeDialog() {
      this.addArr = { card_type: "", weight: "" }; //清空添加修改弹窗数据
      this.row = []; //清空选中数据
    },
    //删除角色
    del(row) {
      this.row = row;
      this.$confirm(
        "你确定要删除银行卡类型：" + row.bank_name + "吗？此操作无法撤回,请谨慎操作",
        "提示",
        { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }
      )
        .then(() => {
          del([{ key: "id", val: this.row.id }])
            .then((res) => {
              if (res.code == 0) {
                this.getList();
                this.$message({ message: res.msg, type: "success" });
              } else {
                this.$message({ message: res.msg, type: "error" });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.form_box {
  overflow: hidden;
  margin-bottom: 18px;
}
.form_title {
  width: 30%;
  float: left;
  height: 32px;
  line-height: 32px;
}
.form_box >>> .el-input,
.form_box >>> .el-textarea {
  width: 60%;
}
.form_box >>> .el-select .el-input {
  width: 100%;
}
</style>
