import { ElMessage } from 'element-plus'

export function orbital(v) {
    switch (v.reg) {
        case "empty":
            if (validateEmpty(v.val, v.title, v.num) !== "OK") return
            break;
        case "account":
            if (validateAccount(v.val, v.title) !== "OK") return
            break;
        case "password":
            if (validatePassword(v.val, v.title) !== "OK") return
            break;
        default:
            break;
    }
    return "OK"
}
// 校验非空
function validateEmpty(value, str, num) {
    if (value == '' || value == undefined || value == null) return ElMessage({ message: str + "不能为空", type: "error" });
    if (value.length() > num) return ElMessage({ message: str + "不能超过" + num + "位", type: "error" });
    return "OK"
}
// 校验账号
function validateAccount(value, str) {
    if (value == "" || value == undefined || value == null) return ElMessage({ message: str + "不能为空", type: "error" });
    const reg = /^[0-9A-Za-z]{6,18}$/;
    if (!reg.test(value) && value != '') return ElMessage({ message: str + "格式错误", type: "error" });
    return "OK"
}
//校验密码
function validatePassword(value, str) {
    if (value == "" || value == undefined || value == null) return ElMessage({ message: str + "不能为空", type: "error" });
    const reg = /^[0-9A-Za-z.!@#$%^&*()-_=+?<>:;]{6,18}$/;
    if (!reg.test(value) && value != '') return ElMessage({ message: str + "格式错误", type: "error" });
    return "OK"
}